import React, { useEffect } from "react" ;
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import MainHeader from "./components/headers/light.js";
import { Subheading as SubheadingBase } from "./components/misc/Headings.js";
import { Link } from "react-router-dom";
import {  Button, } from "react-bootstrap";
import { PrimaryButton as PrimaryButtonBase } from "./components/misc/Buttons2.js";

const CardButton = tw(PrimaryButtonBase)`text-sm m-5`;
const Row = tw.div`flex`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;
const Heading = tw(HeadingBase)`text-center text-primary-900 leading-snug`;
const Subheading = tw(SubheadingBase)`mb-4`;

export default () => {
    useEffect(() => {
      localStorage.setItem('cart', []);
    });
  return (
    <>
    <MainHeader />
    <hr/>
      <Container tw="-mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <HeroRow>
            <Heading>Thank You for Your Order</Heading>
            <br /><br />
            <Subheading>We will be in touch concerning order details and payment</Subheading>
            <br /><br />
            <Link to="/#">
                    <CardButton style={{ width: "95%", margin: "0 10px" }}>
                      Back to Home Page
                    </CardButton>
                  </Link>
          </HeroRow>
        </Content2Xl>
      </Container>
    </>
    );
};
