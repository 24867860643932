import {
  FormControl,
  Navbar,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { CartState } from "../context/Context";
import "./styles.css";
import "./App.css";
import React, { useEffect } from "react" ;

const Header = () => {
  const {
    state: { cart },
    productDispatch,
  } = CartState();
  
  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

return (
    <Navbar bg="faded" variant="dark" style={{ height: 80 }}>
      <div style={{ width: 350 }}></div>
        {useLocation().pathname.split("/")[1] !== "cart" && (
          <Navbar.Text className="search">
            <FormControl
              id="searchTextBox"
              style={{ width: 500 }}
              type="search"
              placeholder="Search a product..."
              className="m-auto"
              aria-label="Search"
              onChange={(e) => {
                productDispatch({
                  type: "FILTER_BY_SEARCH",
                  payload: e.target.value,
                });
              }}
            />
          </Navbar.Text>
        )}
     </Navbar>
  );
};

export default Header;
