import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line
import ComponentRenderer from "ComponentRenderer.js";
import MainLandingPage from "MainLandingPage.js";
import ThankYouPage from "ThankYouPage.js";
import StorePage from "./pages/Store.js";
import RecommendedPage from "./pages/Recommended.js";
import Sourdough101Page from "./pages/Sourdough101.js";
import ContactUsPage from "./pages/ContactUs.js";
import CheckoutForm from "./components/Checkout.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cart from "./components/Cart";
import OrderPage from "./components/Order.js";
//import {PayPalScriptProvider} from "@paypal/react-paypal-js";

export default function App() {

  return (
    // <PayPalScriptProvider
    //   options={{"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID}}
    // >
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/store" element={<OrderPage />} />          
          <Route path="/recommended" element={<RecommendedPage />} />
          <Route path="/sourdough101" element={<Sourdough101Page />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/" element={<MainLandingPage />} />
          <Route path='checkout/' index element={<CheckoutForm />} />
          <Route path='cart/' index element={<Cart />} />
        </Routes>
      </Router>

    </>
    // </PayPalScriptProvider>
  );
}