import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
//import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import img1 from '../../images/items/Proofer.jpg';
import img2 from '../../images/items/Whisk.jpg';
import img3 from '../../images/items/PaulHollywood.jpg';

const Container = tw.div`relative pt-8 pl-20`;
const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 w-64 xl:w-80 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 w-64 xl:w-80 xl:h-80 bg-cover relative rounded-t`}
`;
const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Recommended Products",
  tabs = {
    Products: [
      {
        title: 'Brod & Taylor Folding Proofer',
        description: 'The Folding Proofer offers home cooks the benefits of a commercial kitchen tool - accurate temperature and predictable results - in an elegant appliance that folds for storage.  The temperature and humidity control is perfect for rising and fermenting yeast bread dough or sourdough. In slow cooker mode, enjoy delicious one-pot meals using your own favorite Dutch oven or pot. Great for making yogurt, sous vide, tempering chocolate, and a wide variety of probiotic superfoods including soft cheeses, cultured butter, kombucha, tempeh, and kefir.  Radiant heating warms food to the temperature setting. Proofer mode 70-120F (21-49C) with optional humidity. Slow Cook mode 85-195F (30-90C). (NOTE: inside air temperature may be different than the set temperature)  Folds flat for storage. Touch a button to switch between Slow Cook and Proofer modes. Interior dimensions (open): 14.75 x 12.5 x 8" high. 8.5" high without rack for Slow Cooking. 120V for North America Only.',
        imageSrc: img1,
        url: 'https://a.co/d/h5dwxdI',
        id: 1
      },
      {
        title: 'Brod & Taylor Dough Whisk - Heavy Duty Dishwasher-Safe Mixing Tool',
        description: 'Dishwasher safe, ultra-sturdy construction that is built to last. Extra-thick stainless steel powers through the stiffest doughs, batters, and more.  The right mixing tool is essential when making bread or working with any ingredients that need to be combined quickly and effectively. Most dough whisks are made with an unstable handle attachment to the whisk wire, and these just don’t hold up over time.  Our whisk is a one-piece multi-purpose kitchen tool especially good at combining wet/dry ingredients with little effort or mess. Featuring an innovative non-slip molded silicone handle, the total length is 13.5 inches.',
        imageSrc: img2,
        url: 'https://a.co/d/fOkYHkA',
        id: 2
      },
      {
        title: 'Paul Hollywoods Bread',
        description: 'Paul Hollywoods new book is all about bread - how to make it and how to use it. Not only does Paul teach you exactly how to make a variety of breads, but for each one there is a spin-off recipe that shows you how to make a fantastic meal of it.',
        imageSrc: img3,
        url: 'https://a.co/d/0KdQwSr',
        id: 3
      }
    ]
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
        <HeaderRow>
          <Header>{heading}</Header>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url}  target='_blank' initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.description}</CardContent>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

