export const cartReducer = (state, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      return { ...state, cart: [...state.cart, { ...action.payload, qty: 1 }] };
    case "REMOVE_FROM_CART":
      return {
        ...state,
        cart: state.cart.filter((c) => c.id !== action.payload.id),
      };
    case "CHANGE_CART_QTY":
      return {
        ...state,
        cart: state.cart.filter((c) =>
          c.id === action.payload.id ? (c.qty = action.payload.qty) : c.qty
        ),
      };
    default:
      return state;
  }
};

export const productReducer = (state, action) => {
  switch (action.type) {
    case "FILTER_BY_BREAD":
        return { byBread: true, byBreakfast: false, byDessert: false, byMisc: false };
    case "FILTER_BY_BREAKFAST":
      return { byBread: false, byBreakfast: true, byDessert: false, byMisc: false };
    case "FILTER_BY_DESSERT":
      return { byBread: false, byBreakfast: false, byDessert: true, byMisc: false };
    case "FILTER_BY_MISC":
      return { byBread: false, byBreakfast: false, byDessert: false, byMisc: true };
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "CLEAR_FILTERS":
      return { byBread: false, byBreakfast: false, byDessert: false, byMisc: false };
    default:
      return state;
  }
};
