import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// eslint-disable-next-line
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import Header from "components/headers/light.js";
import TabGrid from "components/cards/TabCardGrid.js";
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
const Subheading = tw(SubheadingBase)`text-center md:text-left`;

function Store() {
    return (
      <>
      <Header />
      <hr/>
        <AnimationRevealPage>
        <>
        <div tw="mt-12 flex flex-col items-center">
        <Subheading>Online Ordering Coming Soon - To Order text details to NOREEN at (678) 790-1989</Subheading>
        </div>
         <TabGrid
        heading={
          <>
            Our <HighlightedText>Products</HighlightedText>
          </>
        }
      />
      </>
        </AnimationRevealPage>
        <Footer/>
        </>)
      }

export default Store;