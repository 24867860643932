import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import SourdoughMainSrc from "images/sour/sourdough_main.png";
import SourdoughMain2Src from "images/sour/sourdough_main_2.png";

export default () => {
  const Subheading = tw.span`tracking-wider text-base font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Freshly Baked<HighlightedText>Sourdough Bread</HighlightedText></>}
        description="The Best tasting sourdough bread you will find! It is great toasted or fresh for your favorite sandwich or snack! Try some today you will love it!! This sourdough bread is absolutely awesome!! It has a wonderful texture and flavor! Great for the weight watchers, having only 80 calories per slice and 0 grams of fat. It is also great for diabetics looking for a good bread having 0 grams of sugar!! This bread is made with no added yeast!"
        imageSrc={SourdoughMainSrc}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
        watchVideoButtonText="Meet The Baker"
      />
      <MainFeature
        subheading={<Subheading>We hand deliver to our neighbors</Subheading>}
        heading={
          <>
            Proudly Serving 
            <wbr /> <HighlightedText>Sutton Fields</HighlightedText>
          </>
        }
        description={
          <Description>

          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={SourdoughMain2Src}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Our <HighlightedText>Products</HighlightedText>
          </>
        }
      />
      <Testimonial
        subheading=""
        heading={<>What Our <HighlightedText>Customers Say</HighlightedText></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
