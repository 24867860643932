import { Form } from "react-bootstrap";
import { CartState } from "../context/Context";
import { PrimaryButton as PrimaryButtonBase2 } from "components/misc/Buttons2.js";
import tw from "twin.macro";

const ClearButton = tw(PrimaryButtonBase2)`inline-block mt-8`

const Filters = () => {
  const {
    productDispatch,
    productState: { byBread, byBreakfast, byDessert, byMisc },
  } = CartState();

 function filterAndClear(filter){
  const textBox = document.getElementById('searchTextBox');
    textBox.value = "";
    productDispatch({
      type: filter
    })
 };

  return (
    <div className="filters">
      <span className="title">Filter Products</span>
      <span>
        <Form.Check
          inline
          label="Bread Only"
          name="group1"
          type="radio"
          id={`inline-1`}
          onChange={() =>
            filterAndClear("FILTER_BY_BREAD")
          }
          checked={byBread}
        />
      </span>
      <span>
        <Form.Check
          inline
          label="Breakfast Only"
          name="group1"
          type="radio"
          id={`inline-2`}
          onChange={() =>
            filterAndClear("FILTER_BY_BREAKFAST")
          }
          checked={byBreakfast}
        />
      </span>
      <span>
        <Form.Check
          inline
          label="Dessert Only"
          name="group1"
          type="radio"
          id={`inline-3`}
          onChange={() =>
            filterAndClear("FILTER_BY_DESSERT")
          }
          checked={byDessert}
        />
      </span>
      <span>
        <Form.Check
          inline
          label="Misc Only"
          name="group1"
          type="radio"
          id={`inline-4`}
          onChange={() =>
            filterAndClear("FILTER_BY_MISC")
          }
          checked={byMisc}
        />
      </span>
      <ClearButton
      bg="faded"
        style={{ color: "black" }}
        variant="light"
        onClick={() =>
          filterAndClear("CLEAR_FILTERS")
        }
      >
        Clear Filters
      </ClearButton>
    </div>
  );
};

export default Filters;
