import { createContext, useContext, useReducer } from "react";
import { cartReducer, productReducer } from "./Reducers";
import { PRODUCTS } from '../products.js';

const Cart = createContext();

const Context = ({ children }) => {
  const products = PRODUCTS;

  const [state, dispatch] = useReducer(cartReducer, {
    products: products,
    cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
  });

  const [productState, productDispatch] = useReducer(productReducer, {
    byBread: false,    
    byBreakfast: false,
    byDessert: false,
    byMisc: false,
    searchQuery: "",
  });

  console.log(productState);

  return (
    <Cart.Provider value={{ state, dispatch, productState, productDispatch }}>
      {children}
    </Cart.Provider>
  );
};

export const CartState = () => {
  return useContext(Cart);
};

export default Context;


// import { PRODUCTS } from '../products.js';
// import { createContext, useContext, useReducer } from "react";
// import { cartReducer, productReducer } from "./Reducers";

// const Cart = createContext();

// const [state, dispatch] = useReducer(cartReducer, {
//     products: PRODUCTS,
//     cart: [],
//   });

//   const [productState, productDispatch] = useReducer(productReducer, {
//     byStock: false,
//     byFastDelivery: false,
//     byRating: 0,
//     searchQuery: "",
//   });

//   console.log(productState);

//    return (
//      <Cart.Provider value={{ state, dispatch, productState, productDispatch }}>
//        {children}
//      </Cart.Provider>
//    );
//  };

// // export const CartState = () => {
// //   return useContext(Cart);
// // };

// // export default Context;


// // import { createContext, useContext, useReducer } from "react";
// // import faker from "faker";
// // import { cartReducer, productReducer } from "./Reducers";

// // const Cart = createContext();
// // faker.seed(99);

// // const Context = ({ children }) => {
// //   const products = [...Array(20)].map(() => ({
// //     id: faker.datatype.uuid(),
// //     name: faker.commerce.productName(),
// //     price: faker.commerce.price(),
// //     image: faker.random.image(),
// //     inStock: faker.random.arrayElement([0, 3, 5, 6, 7]),
// //     fastDelivery: faker.datatype.boolean(),
// //     ratings: faker.random.arrayElement([1, 2, 3, 4, 5]),
// //   }));

// //   const [state, dispatch] = useReducer(cartReducer, {
// //     products: products,
// //     cart: [],
// //   });

// //   const [productState, productDispatch] = useReducer(productReducer, {
// //     byStock: false,
// //     byFastDelivery: false,
// //     byRating: 0,
// //     searchQuery: "",
// //   });

// //   console.log(productState);

// //   return (
// //     <Cart.Provider value={{ state, dispatch, productState, productDispatch }}>
// //       {children}
// //     </Cart.Provider>
// //   );
// // };

// // export const CartState = () => {
// //   return useContext(Cart);
// // };

// // export default Context;





// // const Context = ({ children }) => {
// //    const [state, dispatch] = useReducer(cartReducer, {
// //     products: PRODUCTS,
// //     cart: [],
// //   });

// //   const [productState, productDispatch] = useReducer(productReducer, {
// //   });

// //   console.log(productState);

// //   return (
// //     <Cart.Provider value={{ state, dispatch, productState, productDispatch }}>
// //       {children}
// //     </Cart.Provider>
// //   );
// // };

// // export const CartState = () => {
// //   return useContext(Cart);
// // };

// // export default Context;
