import { CartState } from "../context/Context";
import Filters from "./Filters";
import SingleProduct from "./SingleProduct";
import Header from "./Header";
import MainHeader from "./headers/light.js";
import "./App.css";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";

const Home = () => {
  const {
    state: { products },
    productState: { byBread, byBreakfast, byDessert, byMisc, searchQuery },
  } = CartState();

  const transformProducts = () => {
    let sortedProducts = products;

    if (byBread) {
      sortedProducts = sortedProducts.filter((prod) => prod.bread);
    }

    if (byBreakfast) {
      sortedProducts = sortedProducts.filter((prod) => prod.breakfast);
    }

    if (byDessert) {
      sortedProducts = sortedProducts.filter((prod) => prod.dessert);
    }

    if (byMisc) {
      sortedProducts = sortedProducts.filter((prod) => prod.misc);
    }

    if (searchQuery) {
      sortedProducts = sortedProducts.filter((prod) =>
        prod.title.toLowerCase().includes(searchQuery)
      );
    }

    return sortedProducts;
  };

  return (
    <>
    <MainHeader />
    <hr/>
    <AnimationRevealPage>
    <Header />
    <div className="home">
      <Filters />
      <div className="productContainer">
         {transformProducts().map((prod) => (
          <SingleProduct prod={prod} key={prod.id} />
        ))}
      </div>
    </div>
    </AnimationRevealPage>
    </>
  );
};

export default Home;


// import { CartState } from "../context/Context";
// import SingleProduct from "./SingleProduct";
// //import Header from "./Header";
// import Header from "./headers/light";

// const Home = () => {
//   const {
//     state: { products },
//     productState: { sort },
//   } = CartState();

//   const transformProducts = () => {
//     let sortedProducts = products;
    
//     sortedProducts = sortedProducts.sort((a, b) =>
//       sort === "lowToHigh" ? a.price - b.price : b.price - a.price
//     );

//     return sortedProducts;
//   };

//   return (
//     <div className="home">
//       <Header />
//        <div className="productContainer">
//         {transformProducts().map((prod) => (
//           <SingleProduct prod={prod} key={prod.id} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Home;
