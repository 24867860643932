import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithHeader.js";
import Hero2 from "components/hero/TwoColumn.js";
// import SourdoughMainSrc from "images/sour/sourdough_main.png";
// import SourdoughMainSrc2 from "images/sour/sourdough_main_2.png";
// import SourdoughMainSrc3 from "images/sour/sourdough_round2.png";
// import SourdoughMainSrc4 from "images/sour/sourdough_cranberry.png";
// import SourdoughMainSrc5 from "images/sour/sourdough_main_1.png";
// import SourdoughMainSrc6 from "images/sour/sourdough5.png";
// import SourdoughMainSrc7 from "images/sour/sourdough3.png";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/headers/light.js";
import SourdoughMainSrc from "images/sour/sourdough_round2.png";
// import SourdoughMainSrc2 from "images/sour/brownies.png";
// import SourdoughMainSrc3 from "images/sour/cookies.png";
// import SourdoughMainSrc4 from "images/sour/cheesecake.png";
import SourdoughMainSrc5 from "images/sour/scones.jpg";
//import SourdoughMainSrc6 from "images/sour/cinnamon_rolls.png";
import SourdoughMainSrc7 from "images/sour/sourdough_oval.jpg";
// import SwedishCinnamonRollsSrc from "images/sour/SwedishCinnamonBuns1.jpg";
// import SourdoughCranberrySrc from "images/sour/sourdough_cranberry.png";
// import SauerkrautSrc from "images/sour/sauerkraut.png";
import SourdoughMainSrc2 from "images/sour/GarlicParmesanFocaccia4.jpg";
// import KombuchaSrc from "images/sour/kombucha.png";
import SourdoughMainSrc4 from "images/sour/Muffins2.jpg";
// import Muffins3Src from "images/sour/Muffins3.jpg";
import SourdoughMainSrc3 from "images/sour/babka1.jpg";
// import SourdoughMainSrc6 from "images/sour/babka2.jpg";
import SourdoughMainSrc6 from "images/sour/babka3.jpg";

export default () => {
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

    return (
      <>
      <Header />
      <hr/>
        <AnimationRevealPage>
        <>
      <Hero2
        heading={<>Sourdough  <HighlightedText>Bread</HighlightedText></>}
        description="Sourdough bread was essential to early human societies, and continues to be a staple food in cultures worldwide.
        Sourdough bread is fermented with wild microbes. Both yeast and bacteria participate in the fermentation and leavening of sourdough.
        Not all bread labeled “sourdough” is made using traditional sourdough methods.Reading and understanding a bread label can be beneficial for buying sourdough bread for its potential health benefits
        The popularity of sourdough bread has risen in recent years, with both a significant increase in bakery sales and more home bakers making sourdough bread because of its unique flavor, texture, and potential health benefits. 
        Though sourdough bread may be perceived as a healthier choice, not all recipes or breads titled or labeled as ‘sourdough’ are fermented and leavened solely using wild cultures. 
        Having an understanding of the processes involved in making traditional sourdough bread can help consumers make and/or purchase sourdough products with these potential health benefits."
        imageSrc={SourdoughMainSrc}
        imageCss={imageCss}
        imageDecoratorBlob={true}
      />
      <Hero2
        heading={<>Sourdough <HighlightedText>Starters</HighlightedText></>}
        description="Traditional sourdough bread is made using a starter, which may be obtained from fellow bakers, purchased from food fermentation retailers, or made from scratch by mixing flour and water. A starter contains wild yeasts and bacteria from the surrounding environment. The fermenting microorganisms in a sourdough starter need to be fed fresh flour and water regularly to survive and grow.
        For making sourdough bread at home, starters are often shared or they can be created following best practices to cultivate a healthy and safe starter.
        When using wild yeast and bacteria in bread making, the required time for fermenting bread dough (proofing and rising) is less predictable and generally takes longer, sometimes even being intentionally slowed down using refrigeration of the dough to create desired flavors, texture, and nutritional benefits.
        In contrast, using a packaged commercial baker’s yeast enables the baker to create a bread product in a much shorter amount of time and with more consistent results, but without the potential fermentation benefits and complex flavor."
        imageSrc={SourdoughMainSrc2}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />
    <Hero2
        heading={<>Wild  <HighlightedText>Yeast</HighlightedText></>}
        description="The wild yeast in a sourdough starter are present naturally on flour and in the environment. Wild yeast does not need to be intentionally captured from the air, nor does commercial yeast need to be added when making a sourdough starter. 
        The wild yeast consumes the carbohydrates in the flour and produces alcohol and carbon dioxide gas (CO2) as the primary by-products. The release of CO2 in the dough helps create bread’s airy structure.
        One common type of yeast present in sourdough starters is wild-type Saccharomyces cerevisiae. Different strains of this wild yeast are found naturally in many environments, and each creates distinctly different bread flavors. 
        In contrast, standard bread is made with a single strain of Saccharomyces cerevisiae known colloquially as “baker’s yeast.” 
        This strain has extremely high CO2 output, but it delivers very few flavorful byproducts. The result is a rapid rise but a limited flavor profile.
        The naturally occurring bacteria in a sourdough starter are species of Lactobacillus. They turn sugars from flour into lactic acid and convert the yeast-produced alcohol into acetic acid. These organic acids give sourdough bread its trademark sour flavor and enhanced preservative qualities.
        The wild yeast and bacteria in a sourdough starter are in a symbiotic relationship. Together, they survive better than either organism would survive alone. The bacteria depend on the yeast to break down proteins into peptides (Gänzle, 2014). In return, the bacteria release excess glucose, which is food for yeast fermentation. The bacteria also lower the pH to a point that is optimal for numerous yeast enzymes (Siepmann et al., 2018). There are many different yeasts and bacteria that can be involved in sourdough fermentations. 
        Together, they create countless combinations, each of which gives unique properties to the bread. Some of these combinations are geographically-specific. For example, San Francisco sourdough is famous for the flavor resulting from the combination of wild yeast and bacteria native to that geographic location."
        imageSrc={SourdoughMainSrc3}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />
    <Hero2
        heading={<><HighlightedText>Digestibility</HighlightedText></>}
        description="Traditional sourdough bread made with wild yeast and bacteria has several benefits over bread made with commercial baker's yeast. Because of the organic acids produced by the Lactobacillus bacteria, sourdough has a lower pH than standard bread. This not only gives it a desirable “sour” flavor and longer shelf-life, but also makes the bread kinder to your gut (Marti, et al., 2015; Siepmann, et al., 2018).
        The low pH of sourdough bread, combined with its long fermentation time, allows nutrients, like carbohydrates and proteins with a more complex structure, to get a “head start” on digestion by partially breaking down into smaller units. For these reasons, sourdough has been shown to be more digestible than standard bread fermented with baker’s yeast (Rizello et al., 2019).
        Sourdough fermentation also reduces levels of certain FODMAPs, which are a type of carbohydrate that cause bowel irritation in some people. The low levels of this type of carbohydrate in sourdough makes it much more digestible for certain consumers (Menezes et al., 2019). Sourdough has also been shown to produce less gas and bloating overall, and this may also be due to its low-FODMAP profile (Rizello et al., 2019).
        Research has shown that some minerals may be present in a more available form in sourdough bread. Flour is a source of minerals such as calcium, sodium, zinc, and magnesium. However, these minerals can be sequestered in a molecule known as the phytate complex, which must be broken down in order for humans to absorb the minerals. While baker’s yeast is unable to break down the phytate complex, 
        sourdough organisms can break down the complex very effectively. 
        This could increase the amount of minerals available for absorption in the human gut (Leenhardt et al., 2005; Nionelli &amp; Rizzello, 2016)."
        imageSrc={SourdoughMainSrc4}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />
      <Hero2
        heading={<><HighlightedText>Gluten</HighlightedText></>}
        description="Gluten, composed of two types of proteins named gliadin and glutenin, is produced by wheat, barley, and rye plants and can contribute to the structure and texture of bread products. For individuals with celiac disease, gluten causes damage to the intestinal tract because of an immune system reaction. The only way to manage the symptoms of celiac disease is strict avoidance of gluten in the diet, so any gluten-containing bread (including sourdough bread) would not be tolerated or safe to consume.
        For individuals experiencing non-celiac gluten sensitivity, gluten intolerance, or chronic intestinal issues, gluten-containing products are frequently cut from daily diets. Research is ongoing to determine if the natural fermentation processes of sourdough bread made using a wild yeast and bacteria starter may help alleviate some symptoms for those with gluten intolerance. (See the Reference section for more information, and always consult a medical professional if you have gluten sensitivities before eliminating or introducing gluten-containing foods into your diet.)"
        imageSrc={SourdoughMainSrc5}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />
      <Hero2
        heading={<>Blood  <HighlightedText>Glucose</HighlightedText></>}
        description="Some research suggests that the acids produced during the sourdough fermentation process inhibit the enzymes that metabolize starches, causing the starches to be more slowly digested when consumed. 
        This prevents blood glucose and insulin levels from rising quickly. More research is needed to understand these impacts on humans when consuming sourdough bread. The carbohydrate content should still be factored in when considering impacts on blood glucose levels, especially for those with diabetes"
        imageSrc={SourdoughMainSrc6}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />
    <Hero2
        heading={<>Health  <HighlightedText>Benefits</HighlightedText></>}
        description="Many food products have Standards of Identity (SI) set by the U.S. Food and Drug Administration which outline necessary requirements for that food to be marketed and labeled for sale under a particular name. 
        The SI may include how the food must be produced, what it contains, and the proportions of ingredients. 
        This system of standards provides information for consumers to help make informed decisions regarding the nutrition and safety of their food.
        There is currently no standard of identity for sourdough bread in the U.S. Therefore, checking the ingredient list on the label can help consumers make a more informed sourdough purchase."
        imageSrc={SourdoughMainSrc7}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Order Now"
      />
      </>
      </AnimationRevealPage>
      <Footer />
</>
)};