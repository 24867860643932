import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "../components/headers/light.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import '../recommend.css';
import TabGridRecommended from "components/cards/TabCardGridRecommended.js";

const SectionHeading = tw.h1`text-xl sm:text-2xl tracking-wide text-center`
const Heading = tw(SectionHeading)`text-center font-black text-2xl md:text-2xl text-primary-900 leading-snug`;
//const Heading = tw.h1`text-center font-black text-2xl md:text-2xl leading-snug max-w-2xl`;
const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;

function RecommendedList() {
  return (
    <>
    <Header />
    <hr/>
      <AnimationRevealPage>    
      <>
      <TabGridRecommended
        heading={
          <>
            Recommended <HighlightedText>Products</HighlightedText>
          </>
        }
      />
        {/* {recommends.map((item) => {
          return <Recommend {...item} key={item.id} />;
        })} */}
      </>
      </AnimationRevealPage>
        <Footer/>
        </>
  );
};

export default RecommendedList;