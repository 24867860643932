import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";
import Context from "./context/Context";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
    <Context>
      <App />
    </Context>
  </React.StrictMode>
    );