import SourdoughRoundSrc from "images/sour/sourdough_round2.png";
import SourdoughSesameSrc from "images/sour/SesameCrustedSourdough.jpg";
import PandesalSrc from "images/sour/Pandesal.jpg";
import DinnerRollsSrc from "images/sour/DinnerRolls.jpg";
import StarBreadSrc from "images/sour/StarBread.jpg";
import TreeBreadSrc from "images/sour/TreeBread.jpg";
import SourdoughUbeSrc from "images/sour/UbeSourdough.jpg";
import BrowniesSrc from "images/sour/brownies.png";
import CookiesSrc from "images/sour/cookies.png";
import CheesecakeSrc from "images/sour/cheesecake.png";
import SconesSrc from "images/sour/scones.jpg";
import SconesBlueberrySrc from "images/sour/scones_blueberry.jpg";
import Scones5Src from "images/sour/scones5.jpg";
import CinnamonRollsSrc from "images/sour/cinnamon_rolls.png";
import CinnamonRollsWildBerrySrc from "images/sour/cinnamon_rolls_wild_berry.png";
import SourdoughOvalSrc from "images/sour/sourdough_oval.jpg";
import SwedishCinnamonRollsSrc from "images/sour/SwedishCinnamonBuns1.jpg";
import SourdoughMiniSrc from "images/sour/sourdough_mini.png";
import SourdoughMiniJalapenoCheddarSrc from "images/sour/sourdough_mini_jalapenocheddar.png";
import SourdoughJalapenoCheddarSrc from "images/sour/Sourdough_JalapenoCheddar.png";
import SauerkrautSrc from "images/sour/sauerkraut.png";
import SourdoughFocacciaSrc from "images/sour/GarlicParmesanFocaccia4.jpg";
import KombuchaSrc from "images/sour/kombucha.png";
import MuffinsSrc from "images/sour/Muffins2.jpg";
import Muffins3Src from "images/sour/Muffins3.jpg";
import Babka1Src from "images/sour/babka1.jpg";
import Babka2Src from "images/sour/babka2.jpg";
import Babka3Src from "images/sour/babka3.jpg";
import Babka4Src from "images/sour/babka4.jpg";

export const PRODUCTS = [
        {
          id:1,
          imageSrc:SourdoughOvalSrc,
          title: "Sourdough - Oval",
          content: "",
          price: 10.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water",
          allergens: "Allergens: Wheat"
        },
        {
          id:2,
          imageSrc:SourdoughRoundSrc,
          title: "Sourdough - Round",
          content: "",
          price: 10.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water",
          allergens: "Allergens: Wheat"
        },
        {
          id:3,
          imageSrc:SourdoughMiniSrc,
          title: "Sourdough - Bread (Mini)",
          content: "",
          price: 6.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water",
          allergens: "Allergens: Wheat"
        },
        {
          id:4,
          imageSrc:SourdoughJalapenoCheddarSrc,
          title: "Sourdough - Jalapeno/Cheddar",
          content: "",
          price: 12.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water, Cheddar Cheese, Jalapeno",
          allergens: "Allergens: Wheat, Milk"
        },
        {
          id:5,
          imageSrc:SourdoughMiniJalapenoCheddarSrc,
          title: "Sourdough - Jalapeno/Cheddar (Mini)",
          content: "",
          price: 7.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water, Cheddar Cheese, Jalapeno",
          allergens: "Allergens: Wheat, Milk"
        },
        // {
        //   id:3,
        //   imageSrc:StarBreadSrc,
        //   title: "Sourdough - Star Bread",
        //   content: "",
        //   price: 25.00,
        //   rating: "",
        //   reviews: "",
        //   url: "store",
        //   bread: true,          
        //   breakfast: false,
        //   dessert: false,          
        //   misc: false,
        //   inStock: 10,
        //   ratings: 5,
        //   ingredients: "Ingredients: Flour, Salt, Water, Raspberries, White Chocolate, Powdered Sugar",
        //   allergens: "Allergens: Wheat"
        // },
        // {
        //   id:4,
        //   imageSrc:TreeBreadSrc,
        //   title: "Savory Sourdough - Christmas Tree",
        //   content: "",
        //   price: 25.00,
        //   rating: "",
        //   reviews: "",
        //   url: "store",
        //   bread: true,          
        //   breakfast: false,
        //   dessert: false,          
        //   misc: false,
        //   inStock: 10,
        //   ratings: 5,
        //   ingredients: "Ingredients: Flour, Salt, Milk, Egg, Sugar, Butter, Parmesan Cheese, Garlic, Herbs",
        //   allergens: "Allergens: Wheat"
        // }, 
        {
          id:6,
          imageSrc:SourdoughUbeSrc,
          title: "Sourdough - Ube",
          content: "",
          price: 12.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water, Ube Concentrate",
          allergens: "Allergens: Wheat"
        },       
        {
          id:7,
          imageSrc:SourdoughSesameSrc,
          title: "Sourdough - Sesame Crusted",
          content: "",
          price: 11.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water, Sesame Seeds",
          allergens: "Allergens: Wheat"
        },
        {
          id:8,
          imageSrc:SourdoughFocacciaSrc,
          title: "Sourdough - Garlic Parmesan Focaccia",
          content: "",
          price: 12.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Salt, Water, Olive Oil, Garlic, Parmesan Cheese",
          allergens: "Allergens: Wheat, Milk"
        }, 
        {
          id:9,
          imageSrc:Babka1Src,
          title: "Sourdough Babka - Raspberry/White Chocolate",
          content: "",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Milk, Eggs, Butter, Salt, Raspberries, White Chocolate",
          allergens: "Allergens: Wheat, Milk, Egg"
        },        
        {
          id:10,
          imageSrc:Babka2Src,
          title: "Sourdough Babka - Chocolate",
          content: "",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Milk, Eggs, Butter, Salt, Sugar, Chocolate",
          allergens: "Allergens: Wheat, Milk, Egg"
        },
        {
          id:11,
          imageSrc:Babka3Src,
          title: "Sourdough Babka - Cinnamon",
          content: "",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Milk, Eggs, Butter, Sugar, Salt, Cinnamon",
          allergens: "Allergens: Wheat, Milk, Egg"
        },          
        {
          id:12,
          imageSrc:Babka4Src,
          title: "Sourdough Babka - Caramel Coconut",
          content: "",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: true,          
          breakfast: false,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Milk, Eggs, Butter, Sugar, Salt, Caramel, Coconut",
          allergens: "Allergens: Wheat, Milk, Egg"
        },
          {
            id:13,
            imageSrc:PandesalSrc,
            title: "Pandesal - Filipino Dinner Rolls",
            content: "12 ct",
            price: 10.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: true,          
            breakfast: false,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Water, Egg, Sugar, Salt, Vegetable Oil, Bread Crumbs",
            allergens: "Allergens: Wheat, Egg"
          }, 
          {
            id:14,
            imageSrc:DinnerRollsSrc,
            title: "Sourdough Soft Dinner Rolls",
            content: "12 ct",
            price: 12.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: true,          
            breakfast: false,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Milk, Butter, Sugar, Salt",
            allergens: "Allergens: Wheat"
          },
          {
            id:20,
            imageSrc:MuffinsSrc,
            title: "Sourdough Muffins (Blueberry/Strawberry) 12ct",
            content: "12 ct",
            price: 17.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: false,          
            breakfast: true,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Sugar, Butter, Eggs, Sour Cream, Vanilla, Extract, Salt, Baking Powder, Baking Soda, Lemon Zest, Corn Starch, Fresh Blueberries/Strawberries",
            allergens: "Allergens: Wheat, Milk, Egg"
          },        
          {
            id:22,
            imageSrc:Muffins3Src,
            title: "Sourdough Muffins (Blueberry/Strawberry) 6ct",
            content: "6 ct",
            price: 10.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: false,          
            breakfast: true,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Sugar, Butter, Eggs, Sour Cream, Vanilla, Extract, Salt, Baking Powder, Baking Soda, Lemon Zest, Corn Starch, Fresh Blueberries/Strawberries",
            allergens: "Allergens: Wheat, Milk, Egg"
          },        
          {
            id:23,
            imageSrc:CinnamonRollsSrc,
            title: "Sourdough Cinnamon Rolls",
            content: "12 ct",
            price: 18.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: false,          
            breakfast: true,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Butter, Buttermilk, Honey, Salt, Brown Sugar, Cinnamon, Milk, Vanilla Extract",
            allergens: "Allergens: Wheat, Milk"
          },        {
            id:24,
            imageSrc:CinnamonRollsWildBerrySrc,
            title: "Sourdough Sweet Berry Rolls",
            content: "12 ct",
            price: 20.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: false,          
            breakfast: true,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Butter, Buttermilk, Honey, Salt, Brown Sugar, Cinnamon, Milk, Vanilla Extract, Strawberry, Blueberry",
            allergens: "Allergens: Wheat, Milk"
          },       
          {
            id:25,
            imageSrc:SwedishCinnamonRollsSrc,
            title: "Sourdough Swedish Rolls",
            content: "10 ct",
            price: 18.00,
            rating: "",
            reviews: "",
            url: "store",
            bread: false,          
            breakfast: true,
            dessert: false,          
            misc: false,
            inStock: 10,
            ratings: 5,
            ingredients: "Ingredients: Flour, Butter, Buttermilk, Honey, Salt, Brown Sugar, Cinnamon, Milk, Vanilla Extract, Cardamon",
            allergens: "Allergens: Wheat, Milk"
          },       
          {
          id:26,
          imageSrc:Scones5Src,
          title: "Sourdough Scones - Cranberry & White Chocolate 5ct",
          content: "5 ct",
          price: 10.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: true,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Sugar, Butter, Salt, White Chocolate, Dried Cranberries, Baking Powder, Egg, Heavy Cream, Vanilla Extract",
          allergens: "Allergens: Wheat, Milk, Egg"
        },        
        {
          id:27,
          imageSrc:SconesSrc,
          title: "Sourdough Scones - Cranberry & White Chocolate 10ct",
          content: "10 ct",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: true,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Sugar, Butter, Salt, White Chocolate, Dried Cranberries, Baking Powder, Egg, Heavy Cream, Vanilla Extract",
          allergens: "Allergens: Wheat, Milk, Egg"
        },
        {
          id:28,
          imageSrc:SconesBlueberrySrc,
          title: "Sourdough Scones - Blueberry with White Chocolate Drizzle",
          content: "5 ct",
          price: 10.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: true,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Sugar, Butter, Salt, White Chocolate, fresh blueberries, Baking Powder, Egg, Heavy Cream, Vanilla Extract",
          allergens: "Allergens: Wheat, Milk, Egg"
        },
        {
          id:29,
          imageSrc:SconesBlueberrySrc,
          title: "Sourdough Scones - Blueberry with White Chocolate Drizzle",
          content: "10 ct",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: true,
          dessert: false,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Sugar, Butter, Salt, White Chocolate, fresh blueberries, Baking Powder, Egg, Heavy Cream, Vanilla Extract",
          allergens: "Allergens: Wheat, Milk, Egg"
        },       
        {
          id:30,
          imageSrc:BrowniesSrc,
          title: "Brownies",
          content: "10 ct",
          price: 17.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: false,
          dessert: true,          
          misc: false,
          inStock: 0,
          ratings: 5,
          ingredients: "Ingredients: ",
          allergens: "Allergens: "
        },       
        {
          id:31,
          imageSrc:CookiesSrc,
          title: "Chocolate Chip/Butterscotch Cookies",
          content: "12 ct",
          price: 16.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: false,
          dessert: true,          
          misc: false,
          inStock: 10,
          ratings: 5,
          ingredients: "Ingredients: Flour, Baking Soda, Corn Starch, Salt, Butter, Sugar, Eggs, Vanilla Extract, Butterscotch, Chocolate Chips",
          allergens: "Allergens: "
        },       
        {
          id:32,
          imageSrc:CheesecakeSrc,
          title: "Cheesecake",
          content: "",
          price: 50.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: false,
          dessert: true,          
          misc: false,
          inStock: 0,
          ratings: 5,
          ingredients: "Ingredients: ",
          allergens: "Allergens: "
        },       
        {
          id:40,
          imageSrc:SauerkrautSrc,
          title: "Sauerkraut",
          content: "12 oz",
          price: 10.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: false,
          dessert: false,          
          misc: true,
          inStock: 0,
          ratings: 5,
          ingredients: "Ingredients: Cabbage, Salt",
          allergens: "Allergens: "
        },       
        {
          id:41,
          imageSrc:KombuchaSrc,
          title: "Kombucha",
          content: "",
          price: 10.00,
          rating: "",
          reviews: "",
          url: "store",
          bread: false,          
          breakfast: false,
          dessert: false,          
          misc: true,
          inStock: 0,
          ratings: 5,
          ingredients: "Ingredients: ",
          allergens: "Allergens: "
        },        
       ];
