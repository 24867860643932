import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons2.js";
import React, { useState, useEffect } from "react" ;
import { Button, Col, Form, Image, ListGroup, Row } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { CartState } from "../context/Context";
import MainHeader from "./headers/light.js";
import "./App.css";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
//import PaypalCheckoutButton from "./PaypalCheckoutButton";
import CartEmailForm from "components/forms/CartEmailForm.js";

const CardButton = tw(PrimaryButtonBase)`text-sm m-5`;
const Cart = () => {
  const {
    state: { cart },
    dispatch,
  } = CartState();

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);
  
  const [total, setTotal] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTotal(
      cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0),
    );
  }, [cart]);

  // const product = {
  //   description: "Noreen's Sourdough To Go Purchase",
  //   price: 29,
  // };
  
  return (
    <>
    <MainHeader />
    <hr/><br/>
    {/* <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}> */}
    <div style={{marginLeft: "4em"}}>
        <b>NOTE:  All orders require a 2-3 day lead time before delivery or pick-up</b>
      </div>
    <AnimationRevealPage>
    <div className="home">
      <div className="productContainer" style={{ width: "50%", float: "left" }}>
        <ListGroup>
          {cart.map((prod) => (
            <ListGroup.Item key={prod.id}>
              <Row>
                <Col md={2}>
                  <Image src={prod.imageSrc} alt={prod.title} fluid rounded />
                </Col>
                <Col md={3}>
                  <span>{prod.title}</span>
                </Col>
                <Col md={2}>${prod.price}</Col>
                <Col md={1}>Qty</Col>
                <Col md={2}>
                <Form.Control
                    as="select"
                    value={prod.qty}
                    disabled={show === true}
                    onChange={(e) =>
                      dispatch({
                        type: "CHANGE_CART_QTY",
                        payload: {
                          id: prod.id,
                          qty: e.target.value,
                        },
                      })
                    }
                  >
                    {[...Array(5).keys()].map((x) => (
                      <option key={x + 1}>{x + 1}</option>
                    ))}
                  </Form.Control>
                  </Col>
                <Col md={2}>
                  <Button
                    type="button"
                    variant="light"
                    disabled={show === true}
                    onClick={() =>
                      dispatch({
                        type: "REMOVE_FROM_CART",
                        payload: prod,
                      })
                    }
                  >
                    <AiFillDelete fontSize="20px" />
                  </Button>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
      <div style={{ width: "50%", float: "left" }}>
      {!show ? (
        <CartEmailForm parentCart={cart}></CartEmailForm>
      ) : null}
      </div>
      {/* <div className="filters summary">
      {show ? (  
      <span style={{ fontWeight: 700, fontSize: 15, color: "Red", backgroundColor: "white" }}>
        Checkout Mode - Click "CANCEL" to edit cart</span>
      ) : null}
        <span className="title">Subtotal ({cart.length}) items</span>
        <span style={{ fontWeight: 700, fontSize: 20 }}>Total: ${total}</span>
          {!show ? (
          <CardButton type="button" disabled={cart.length === 0}
                  bg="faded"
                  style={{ backgroundColor: "white", color: "black" }}
                  variant="dark"
                  onClick={() => setShow(true)}>
            Checkout
            </CardButton>
            ) : null}
            {show ? (
            <CardButton type="button" disabled={show === false}
                  bg="faded"
                  style={{ backgroundColor: "white", color: "black" }}
                  variant="dark"
                  onClick={() => setShow(false)}>
            Cancel
            </CardButton>
            ) : null}
          <br></br>
          
                {show ? (
                  <div className="paypal">
                    <div className="paypal-button-container">
                        <PaypalCheckoutButton product={product} />
                    </div>
                  </div>
                ) : null}
      </div> */}
    </div>
    </AnimationRevealPage>
    {/* </PayPalScriptProvider> */}
    </>
  );
};

export default Cart;
